import { postLogin, postSignUp, postForgotPassword, putResetPassword } from "src/api/sessionApi";
import  jwt_decode from 'jwt-decode';
import { setAuthToken, resetAuthToken, getAuthToken } from "src/utils/token";
import { ROLE_ADMIN } from "src/components/AuthGuard";
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';

export const checkAuth = (data) => (dispatch) => {
    const jwt = getAuthToken();
    if (jwt) {
        const decodedToken = jwt_decode(jwt);
        const payload = JSON.parse(decodedToken.sub);
        const role = (payload.role || 'user').toUpperCase();
        
        dispatch({
            type: SESSION_LOGIN,
            payload: {
                first_name: payload.firstName,
                last_name: payload.lastName,
                companyName: payload.companyName,
                companyId: payload.mandantKey,
                companyViewType: payload.mandantViewTyp,
                email: '',
                avatar: '/images/avatars/avatar_11.png',
                bio: role === ROLE_ADMIN ? 'admin' : 'user',
                role: role,
                loaded: true
            },
        });
    } else {
        dispatch({
            type: SESSION_LOGOUT,
            payload: {
                loaded: true
            },
        });
    }
}

export const login = (data, enqueueSnackbar) => (dispatch) => {
    return new Promise((resolve, reject) => {
        postLogin(data).then((response) => {
            const token = jwt_decode(response.data.jwt);
            setAuthToken(response.data.jwt);
            const payload = JSON.parse(token.sub);
            resolve();
            return dispatch({
                type: SESSION_LOGIN,
                payload: {
                    first_name: payload.firstName,
                    last_name: payload.lastName,
                    companyName: payload.companyName,
                    companyId: payload.mandantKey,
                    companyViewType: payload.mandantViewTyp,
                    email: '',
                    avatar: '/images/avatars/avatar_11.png',
                    bio: payload.role === ROLE_ADMIN ? 'admin' : 'user',
                    role: payload.role
                },
            })
        }, (error) => {
            console.error(error);
            reject();
        });
    });
};

export const signUp = (data, enqueueSnackbar) => (dispatch) => {
    return new Promise((resolve, reject) => {
        postSignUp(data).then((response) => {
            resolve();
        }, (error) => {
            console.error(error);
            reject();
        });
    });
}

export const forgotPassword = (data, enqueueSnackbar) => (dispatch) => {
    return new Promise((resolve, reject) => {
        postForgotPassword(data).then((response) => {
            resolve();
        }, (error) => {
            console.error(error);
            reject();
        });
    });
}

export const resetPassword = (data, token, enqueueSnackbar) => (dispatch) => {
    return new Promise((resolve, reject) => {
        putResetPassword(data, token).then((response) => {
            resolve();
        }, (error) => {
            console.error(error);
            reject();
        });
    });
}


export const logout = () => (dispatch) => {
    resetAuthToken();
    dispatch({
        type: SESSION_LOGOUT
    })
};
